import * as React from 'react'
import '../style/common.css'
import '../style/large.css'
import 'lightgallery.js/dist/css/lightgallery.css'
import { Footer } from '../components/Footer'
import { STUDIO } from '../images/images'
import { Header } from '../components/Header'
import { RoomDetails } from '../components/RoomDetails'
import {getInitLanguage} from '../services/i18n.service'
import {SEO} from '../components/seo'


// styles
const pageStyles = {
  color: '#484848',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const StudioPage = () => {
  const [locale, setLocale] = React.useState(getInitLanguage())

  const roomDetails = [ `${locale.capacity}: 2-4 ${locale.adults}`,locale.kitchen, 
    locale.tv,locale.refrigerator, locale.air_conditioning, locale.wifi,
    locale.kettle, locale.balcony,locale.sewing, locale.iron, locale.soap, 
    locale.hair_dryer,locale.baggage]


  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  
  React.useEffect(()=>{
    setLocale(getInitLanguage())
  }, []) 

  return <main style={pageStyles}>
    <SEO/> 
    <Header setIsMenuOpen={setIsMenuOpen} 
      isMenuOpen={isMenuOpen} locale={locale} 
      setLocale={setLocale}>
    </Header>
    <RoomDetails pictures={STUDIO} locale={locale} group={'studio'}
      title={locale.studio} roomDetails={roomDetails} linkAfter={'/standard'} textAfter={locale.double}>
    </RoomDetails>
    <Footer />
  </main>
}


export default StudioPage
